@include media-breakpoint-down(md) {
    .navigation-logo {
        padding: .75em;
    }
    
}

.navigation-header {
    justify-content: initial !important;
}


.navigation {
    height: 130px !important;

    @include media-breakpoint-down(md) {
        height: 70px !important;
    }
    
    @include media-breakpoint-down(lg) {
        height: 130px !important;
    }

    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.05);
    transition: background .3s, height .3s;
}
.nav-link {
    font-weight: 300;
}

.navigation-animated {
    height: 70px;
}

.nav-slash {
    .nav-link {
        padding-left: 6px;
        padding-right: 6px;

    }
}

.meta-navigation {
    .nav-item {
        &:after {
            color: #9B9B9B;
        }
    }

    .nav-link {
        color: #9B9B9B;

        &:hover,
        &.active,
        &.current {
            color: #333;
        }
    }
}

.login {
    float: right;

    .nav-link {
        padding-right: 0;
    }
}

.meta-navigation {
    height: 40px;
}

nav.nav {
    a {
        &:first-child {
            @extend .pl-0;
        }

        &:last-child {
            @extend .pr-0;
        }
    }
}

.bottombar {
    .col {
        background: $color-bg-dark !important;
        margin-left: 15px;
        margin-right: 15px;
    }

    font-size: .75em;

    .nav {
        .nav-link {
            &.normal {
                color: #9B9B9B !important;
            }

            &.current,
            &.active,
            &:hover {
                color: $white !important;
            }
        }
    }

    width: 100%;
    position: absolute;
    top: $header-height;
    left: 0;
    height: $bottom-header-height;
}

.subNavigation {
    @extend .navbar-light;
    background: $color-bg-dark;

    a.nav-link {
        line-height: 3.5em;
        text-transform: initial;
    }
}

.tabNav {
    @extend .nav-tabs-minimal;
    border-bottom: 2px solid $color-bg-lighter;
    margin-bottom: 2em;

    a.nav-link {
        position: relative;
        top: 2px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 1em;
        color: $color-text-light;
        padding-bottom: 40px;

        &.active {
            color: $color-text !important;
            border-bottom-color: $color-text;
        }

        &:hover {
            color: $color-text !important;
        }
    }
}
