.abte-map {

    .controls {
        padding: 40px 30px;
        width: auto;
        height: auto;
        z-index: 10;
        background: rgba($white, 0.95);
        position: absolute;

        label {
            width: 100%;
            height: 2em;
            margin: 1em 0;
            padding: .35em 0 .25em 3.5em;
            display: block;
            position: relative;
            background: transparent;

            &:before {
                top: 0;
                left: 0;
                width: 35px;
                height: 35px;
                content: ' ';
                display: block;
                border: 2px solid $color-bg-dark;
                border-radius: 50%;
                position: absolute;
            }

            &:after {
                top: 10px;
                left: 10px;
                width: 15px;
                height: 15px;
                border: 2px solid transparent;
                content: ' ';
                display: block;
                position: absolute;
                background: transparent;
                border-radius: 50%;
            }
        }

        input {
            position: absolute;
            visibility: hidden;

            &:checked+label:after {
                background-color: $color-primary;
            }
        }
    }

    .map {
        width: 100%;
        height: 100%;
        min-height: 600px;
        display: block;
        position: relative;

        &:after {
            top: 0;
            left: 0;
            opacity: 1;
            width: 100%;
            height: 100%;
            content: ' ';
            display: block;
            position: absolute;
            transition: opacity 500ms ease, background 500ms ease, height 0ms ease 500ms;
            background: $white url(../img/loading.gif) no-repeat;
            background-position: center center;
            background-size: 64px auto;
        }

        &.ready {

            &:after {
                height: 0;
                opacity: 0;
                background: transparent;
            }
        }
    }
}
